if (window.location.pathname === "/") {
  // スクロール時の処理を設定
  window.addEventListener("scroll", function () {
    let header = document.querySelector(".l-header");
    let topHero = document.querySelector(".p-top-hero");

    // #js-top-informationが表示されているかを確認
    if (window.scrollY > topHero.offsetHeight / 3) {
      // #js-top-informationに到達した場合、.l-headerにis-scrolledクラスを追加
      header.classList.add("is-scrolled");
    } else {
      // #js-top-informationを過ぎた場合、.l-headerからis-scrolledクラスを削除
      header.classList.remove("is-scrolled");
    }
  });
}

// カレントの取得
const headerNavLink = document.querySelectorAll('.l-header-nav__global__list__item__link');

headerNavLink.forEach((targetLink) => {
  if (targetLink.href === location.href) {
    targetLink.parentElement.classList.add('is-here');
  }
});
