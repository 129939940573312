if(document.querySelector(".p-top-hero")) {
  document.addEventListener("DOMContentLoaded", function () {
    let topHero = document.querySelector(".p-top-hero");

    function updateOpacity() {
        // スクロール位置が .p-top-hero の高さの1/3を越えたらfade-outクラスを追加、それ以下なら削除
        // if (window.scrollY > (topHero.offsetHeight * 2) / 3)
        if (window.scrollY > topHero.offsetHeight / 3) {
            topHero.classList.add("fade-out");
        } else {
            topHero.classList.remove("fade-out");
        }
    }

    // 初期状態の透明度を設定
    updateOpacity();

    // スクロールイベントを監視
    window.addEventListener("scroll", function () {
        updateOpacity();
    });
});
}



